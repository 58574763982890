// 共通のスタイルのみを定義
// 各スタイルは、styled-componentsで定義
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&family=Open+Sans:wght@400;500&display=swap');

* {
  padding: 0;
  margin: 0;
  font-family: 'Noto Sans JP', sans-serif;
  box-sizing: border-box;
}

body {
  background-color: #EFEFEF;
}